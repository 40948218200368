<template>
    <div class="home-box">
      <div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">业务合伙人</span>
        </div>
        <div class="el-dialog-header-box">
          <div class="dialog-title-block"></div>
          <span class="dialog-title-text">登录地址：<a :href="a_url" target="_blank">{{a_url}}</a></span>
        </div>
        <el-divider />
      </div>

      <!-- 查询区域 -->
      <div class="top-query">
        <div class="top-query-left">
          <div class="demo-input-suffix">
            联系人名称：
            <el-input placeholder="请输入联系人名称" prefix-icon="el-icon-search" v-model="username" clearable></el-input>
          </div>
          <div class="demo-input-suffix">
            联系人ID：
            <el-input placeholder="请输入联系人ID" prefix-icon="el-icon-search" v-model="search_agentid" clearable></el-input>
          </div>
          <div class="demo-input-suffix">
            手机号：
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-search"
              type="number"
              class="inputFund"
              oninput="value=value.replace(/[^\d]/g,'')"
              v-model="mobile"
              clearable
            ></el-input>
          </div>
          <el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
          <el-button @click="add" type="success" icon="el-icon-plus">新增</el-button>
        </div>
  
        <div class="top-query-right"></div>
      </div>
  
      <!-- table区域-begin -->
      <div class="table-box">
        <el-table :data="tableData.data" stripe style="width: 100%" v-loading="loading">
          <el-table-column prop="id" label="ID" width="120">
          </el-table-column>
          <el-table-column prop="username" label="联系人" min-width="120">
          </el-table-column>
          <el-table-column prop="mobile" label="电话" min-width="120">
          </el-table-column>
          <el-table-column prop="total_balance" label="余额" min-width="120">
            <template slot-scope="scope">
              <div @click="handleDetail(scope.row)">
                <span>可提现：{{ scope.row.balance }}</span><br>
                <span>累计收益：{{ scope.row.total_balance }}</span><br>
                <span>已提现：{{ scope.row.tx_balance }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="状态" min-width="120">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status == 1 ? '' : 'danger'">{{ scope.row.status == 1 ? '显示' : '禁用' }}</el-tag>
            </template>>
          </el-table-column>
          <el-table-column prop="createdate" label="添加时间" min-width="120">
          </el-table-column>
          <el-table-column fixed="right" header-align="center" align="center" label="操作" min-width="220">
            <template slot-scope="scope">
              <el-button type="primary" plain size="small" @click="handleEdit(scope.row)"
                >编辑</el-button
              >
              <el-button type="primary" plain size="small" @click="balance(scope.row)"
                >余额</el-button
              >
              <el-button type="primary" plain size="small" @click="promoter(scope.row)"
                >推广员</el-button
              >
              <!-- <el-button type="text" size="small" @click="handleDelete(record)"
                >删除</el-button
              > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- table区域-end -->
  
      <!-- 分页区域-begin -->
      <div>
        <el-pagination
          class="pagination"
          background layout="prev, pager, next"
          :total="tableData.total"
          :page-size="tableData.per_page"
          :current-page="page"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
        </el-pagination>
      </div>
      <!-- 分页区域-end -->

      <!-- dialog -->
      <el-dialog :title="title" :visible.sync="dialogFormVisible" @close="dialogFormClose('form')" :width="dialogWidth">
        <div v-if="dialogType=='detail'" >
          <!-- 查询区域 -->
          <div class="top-query">
            <div class="top-query-left">
              <div class="demo-input-suffix">
                审核状态：
                <el-select v-model="status" placeholder="请选择" clearable >
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <el-button type="primary" @click="agentSearch" icon="el-icon-search">搜索</el-button>
              <!-- <el-button @click="add" type="success" icon="el-icon-plus">创建店</el-button> -->
            </div>

            <div class="top-query-right"></div>
          </div>

          <!-- table区域-begin -->
          <el-table :data="detailTableData.data" stripe style="width: 100%" v-loading="balanceLoading">
            <el-table-column prop="id" label="ID" width="120">
            </el-table-column>
            <el-table-column prop="old_balance" label="余额" min-width="120">
            </el-table-column>
            <el-table-column prop="agentid" label="订单ID" min-width="120">
            </el-table-column>
            <el-table-column prop="balance" label="当前余额	" min-width="120">
            </el-table-column>
            <el-table-column prop="balance" label="手续费" min-width="120">
            </el-table-column>
            <el-table-column prop="status" label="状态" min-width="120">
              <template slot-scope="scope">
                <span>{{ statusObject[scope.row.status] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" min-width="120">
              <template slot-scope="scope">
                <span>{{ typeObject[scope.row.type] }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" min-width="120">
            </el-table-column>
            <el-table-column prop="createdate" label="添加时间" min-width="120">
            </el-table-column>
          </el-table>
          <!-- table区域-end -->

          <!-- 分页区域-begin -->
          <div>
            <el-pagination
              class="pagination"
              background layout="prev, pager, next"
              :total="detailTableData.total"
              :page-size="detailTableData.per_page"
              :current-page="pagerPage"
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2">
            </el-pagination>
          </div>
          <!-- 分页区域-end -->
        </div>

        <el-form v-else :model="form" :rules="rules" ref="form" v-loading="detailLoading">
          <div v-if="dialogType =='add' || dialogType =='edit'">
            <el-form-item label="姓名" :label-width="formLabelWidth" prop="username">
              <el-input v-model="form.username" style="width: 90%" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="电话" :label-width="formLabelWidth" prop="mobile">
              <el-input v-model="form.mobile" autocomplete="off" style="width: 90%" placeholder="用于负责人登录，使用"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" v-if="dialogType =='add'" :label-width="formLabelWidth" prop="password">
              <el-input v-model="form.password" autocomplete="off" style="width: 90%" placeholder="不输入，默认密码123456"></el-input>
            </el-form-item>
            <el-form-item label="登录密码" v-if="dialogType =='edit'" :label-width="formLabelWidth" prop="password">
              <el-input v-model="form.password" autocomplete="off" style="width: 90%" placeholder="不输入，默认不修改密码"></el-input>
            </el-form-item>
            <el-form-item label="佣金" :label-width="formLabelWidth" prop="rate">
              <el-input v-model="form.rate" style="width: 90%" placeholder="百分比">
                <template slot="append">%</template></el-input>
            </el-form-item>
            <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
              <el-radio-group v-model="form.status" style="width: 90%">
                <el-radio :label="1">显示</el-radio>
                <el-radio :label="2">禁用</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>

          <div v-if="dialogType =='setAmount'">
            <el-form-item label="当前可提现余额" :label-width="formLabelWidth" prop="balance">
              <el-input v-model="form.balance" disabled style="width: 90%"></el-input>
            </el-form-item>
            <el-form-item label="状态" :label-width="formLabelWidth" prop="status">
              <el-radio-group v-model="form.status" style="width: 90%">
                <el-radio :label="1">增加</el-radio>
                <el-radio :label="2">减少</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="余额" :label-width="formLabelWidth" prop="current_balance">
              <el-input v-model="form.current_balance" placeholder="余额" style="width: 90%"></el-input>
            </el-form-item>
            <el-form-item label="描述" :label-width="formLabelWidth" prop="remark">
              <el-input v-model="form.remark" placeholder="备注" style="width: 90%"></el-input>
            </el-form-item>
          </div>
        </el-form>
        
        <div slot="footer" class="dialog-footer center">
          <el-button @click="dialogFormClose('form')">取 消</el-button>
          <el-button v-if="dialogType!='detail'" type="primary" @click="dialogFormSubmit('form')">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  
  export default({
    name: "partner",
    data() {
      return {
        username: "",
        search_agentid: "",
        mobile: "",
        tableData: {
          total: 10,
          per_page: 10,
        },
        loading: false,
        title: '',
        dialogType: 'add',
        dialogFormVisible: false,
        dialogWidth: '',
        form: {
          username: '',
          mobile: '',
          password: '',
          rate: '',
          status: '',
          
          currentAmount: '',
          balance: '',
          current_balance: '',
          remark: '',
        },
        rules: {
          amountState: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ],
          amount: [
            { required: true, message: '请输入金额', trigger: 'blur' }
          ],
          status: [
            { required: true, message: '请选择状态', trigger: 'blur' }
          ],
          balance: [
            { required: true, message: '请输入金额', trigger: 'blur' }
          ],
          mobile: [
            { required: true, message: '请输入手机号码', trigger: 'blur' },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: "请输入正确的手机号码",
            }
          ],
          current_balance: [
            { required: true, message: '请输入金额', trigger: 'blur' }
          ],
          remark: [
            { required: true, message: '请输入备注', trigger: 'blur' }
          ],
        },
        formLabelWidth: '120px',
        detailLoading: false,
        detailTableData: {},
        typeObject: {},
        typeOptions: [],
        statusObject: {},
        status: '',
        agentid: '',
        balanceLoading: false,
        a_url: '', //当前网址
        page: 1,
        pagerPage: 1,
      };
    },
    computed: {
      ...mapState(["user", "setting"]),
    },
    mounted() {
      const url = new URL(window.location.href)
      this.a_url = url.origin + '/h5'
      let user = JSON.parse(localStorage.user);
      this.token = user.token;
      // this.getautoshow()
      // this.getInfo()
      // this.getechart();
      let data = {
          is_level: 1
        }
      this.getAccount(data)
      // setTimeout(() => {
      //   this.loading = false;
      // }, 1000);
    },
    methods: {
      ...mapMutations(["setuser", "setsetting"]),
      search() {
        console.log('查询');
        let data = {
          username: this.username,
          agentid: this.search_agentid,
          mobile: this.mobile,
          is_level: 1,
          page: this.page
        }
        this.getAccount(data)
      },
      add() {
        this.title = '添加合伙人'
        this.dialogType= 'add'
        // is_level 1:合伙人，2：推广员
        Object.assign(this.form, {status: 1,is_level: 1, password: ''})

        this.dialogFormVisible = true
        console.log('新增');
      },
      handleEdit(record) {
        this.title = '修改合伙人'
        this.dialogType= 'edit'
        this.getDetailAgent(record.id)

        this.dialogFormVisible = true
        console.log('编辑');
      },
      handleDetail(record) {
        this.title = '详情'
        this.dialogType= 'detail'
        this.dialogWidth = '70%'

        this.agentid = record.id
        let data = {
          agentid: record.id,
          page: this.getBalanceList,
        }
        this.getBalanceList(data)

        this.dialogFormVisible = true
        console.log('详情，余额明细');
      },
      handleDelete() {
        console.log('删除');
      },
      balance(record) {
        this.title = '设置余额'
        this.dialogType= 'setAmount'
        // Object.assign(this.form, {agentid: record.id})
        this.getDetailAgent(record.id)

        this.dialogFormVisible = true
        console.log('余额');
      },
      promoter(record) {
        console.log('携带id跳转推广员页面');
        this.$router.push({name:'promoter',params:{inviteid:record.id}});
      },
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.page = val
        this.search()
      },
      handleSizeChange2(val) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange2(val) {
        console.log(`当前页: ${val}`)
        this.pagerPage = val
        let data = {
          agentid: this.agentid,
          page: this.pagerPage,
        }
        this.getBalanceList(data)
      },
      // 弹窗关闭逻辑
      dialogFormClose(formName) {
        if (this.dialogType!='detail') {
          this.$refs[formName].resetFields();                 // 移除表单验证
        }
        Object.assign(this.detailTableData, {})               // 清除数据缓存
        Object.assign(this.typeObject, {})
        this.typeOptions.splice(0, this.typeOptions.length)
        Object.assign(this.statusObject, {})

        this.pagerPage = 1
        this.dialogFormVisible = false                        // 关闭弹窗
      },
      // 表单提交
      dialogFormSubmit(formName) {
        let that = this
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            if (this.dialogType === 'add') {
              this.$api.addAgent({...this.form}).then(
                res => {
                  if(res.code == 'success'){
                    this.getAccount()
                    this.$message.success(res.msg)
                  }else{
                    this.$message.error(res.msg)
                    this.loading = false
                  }
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            else if (this.dialogType === 'edit') {
              this.$api.editAgent({...this.form}).then(
                res => {
                  if(res.code == 'success'){
                    this.getAccount()
                    this.$message.success(res.msg)
                  }else{
                    this.$message.error(res.msg)
                    this.loading = false
                  }
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            else if (this.dialogType === 'setAmount') {
              let data = {
                agentid: this.form.agentid,
                status: this.form.status,
                balance: this.form.current_balance,
                remark:  this.form.remark,
              }
              this.$api.setBalance({...data}).then(
                res => {
                  if(res.code == 'success'){
                    this.getAccount()
                    this.$message.success(res.msg)
                  }else{
                    this.$message.error(res.msg)
                    this.loading = false
                  }
                }
              ).catch(err=>{
                this.$message.error(err.msg)
                this.loading = false
              })
            }
            this.dialogFormVisible = false
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      // 获取提现账号  is_level  1:合伙人，2：推广员
      getAccount(data) {
        this.loading = true
        this.$api.getAgentList({...data}).then(
          res => {
            if(res.code == 'success'){
              this.tableData = res.data
            }else{
              this.$message.error(res.msg)
            }
            this.loading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.loading = false
        })
      },
      // 获取详情（编辑不取行内数据，调用此接口）
      getDetailAgent(agentid) {
        this.detailLoading = true
        this.$api.detailAgent({agentid}).then(
          res => {
            if(res.code == 'success'){
              this.form = Object.assign({agentid: res.data.id}, res.data)
              this.form.password = ''
              console.log('this.form', this.form);
            }else{
              this.$message.error(res.msg)
            }
            this.detailLoading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.detailLoading = false
        })
      },
      // 余额明细查询
      agentSearch() {
        console.log('查询');
        let data = {
          agentid: this.agentid,
          status: this.status,
          page: this.pagerPage,
        }
        this.getBalanceList(data)
      },
      // 获取余额明细
      getBalanceList(data) {
        this.balanceLoading = true
        this.$api.balanceList({...data}).then(
          res => {
            if(res.code == 'success'){
              this.detailTableData = res.data.list
              
              this.typeObject = res.data.type
              this.statusObject = res.data.status
              this.typeOptions.splice(0, this.typeOptions.length)
              Object.entries(res.data.status).map(item => {
                this.typeOptions.push({
                  value: item[0],
                  label: item[1]
                })
              })

            }else{
              this.$message.error(res.msg)
            }
            this.balanceLoading = false
          }
        ).catch(err=>{
          this.$message.error(err.msg)
          this.balanceLoading = false
        })
      }
    },
  });
  </script>
  
  <style scoped lang="less">
  @import "../assets/less/easyElement.less";
  </style>
  